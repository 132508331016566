<template>
  <div id="award-edit">
    <v-card>
      <v-overlay :value="OverlayLoadingFlag">
        <v-progress-circular
          indeterminate
          color="#4A148C"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title>
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <router-link to="/national/lom-award/list">
          <v-btn
            color="blue"
            min-width="92"
            rounded
            medium
            class="font-size-h6 px-3 py-3 mr-3 white--text"
          >
            Back
          </v-btn>
        </router-link>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="btn font-size-h6 px-3 py-3 mr-3 white--text"
          >Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="row != ''">
          <h3 class="text-center text-dark">Award Detail</h3>
          <v-row>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Status</h6>
              <h6 class="text-dark">
                <v-chip
                  :color="row.AwardStatusColor"
                  draggable
                  class="font-size-h5 white--text px-4 py-3"
                >
                  {{ row.AwardStatusTxt }}
                </v-chip>
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Register Number</h6>
              <h6 class="text-dark">
                {{ row.AwardRegisterNumber }}
              </h6>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <h6>Award Title</h6>
              <h6 class="text-dark">
                {{ row.AwardTypeTitleTxt }}
              </h6>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="getTotalNatConLomAwardsDetails"
          >
            <h3 class="text-center text-dark">Judging Criteria</h3>
            <v-row wrap>
              <v-col v-if="row == ''">
                <h5 class="text-center text-dark">Nil.</h5>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-for="(item, index) in row.AwardAnswersArray"
                :key="index"
              >
                <template v-if="item.CriteriaTitleFlag">
                  <h3 class="criteria-title">
                    {{ item.CriteriaTitle }}
                    <small> (score - {{ item.CriteriaScore }}) </small>
                  </h3>
                  <p></p>
                </template>
                <label :for="item.AnswerId" class="criteria-question-text">
                  <h6 class="text-dark">
                    <span class="text-danger">* </span>
                    {{ item.QuestionsText }}
                  </h6>
                </label>
                <v-textarea
                  :id="item.AnswerId"
                  v-model="item.AnswerText"
                  value=""
                  :maxlength="item.QuestionsCharsCount"
                  :counter="item.QuestionsCharsCount"
                  :rules="AnswerRules"
                  outlined
                  solo
                >
                </v-textarea>
                <h6 class="text-dark mt-0">
                  Add / Delete Attachment (max -
                  {{ item.QuestionsAttachmentMax }})
                  <v-btn
                    icon
                    color="blue"
                    v-if="
                      item.QuestionsAttachmentMax > item.EventDetails.length
                    "
                    @click="addNewRow(item)"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </h6>

                <template v-if="item.EventDetails.length > 0">
                  <v-row class="ml-0">
                    <v-col
                      v-for="(data, index) in item.EventDetails"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="3"
                      xl="3"
                      :key="index"
                      v-if="data.EventId != ''"
                    >
                      <v-card>
                        <v-card-text>
                          <h6 class="text-right">
                            <v-btn
                              v-if="data.EventId != ''"
                              icon
                              color="red"
                              @click="deleteRow(item.SNo, index)"
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                          </h6>
                          <h6 align="center">
                            <viewer rebuild :images="data.EventImagesArray">
                              <img
                                style="padding: 5px"
                                width="70"
                                height="70"
                                :src="src"
                                :key="src"
                                v-for="src in data.EventImagesArray"
                              />
                            </viewer>
                          </h6>
                          <h6>
                            Event Date -
                            <span class="text-dark">{{ data.EventDate }}</span>
                          </h6>
                          <!-- <h6>
                              Area-
                              <span class="text-dark">{{
                                data.AnswerPortfolioName
                              }}</span>
                            </h6> -->
                          <h6>
                            Event Name -
                            <span class="text-dark">{{ data.EventName }}</span>
                          </h6>
                        </v-card-text>
                      </v-card>
                      <!-- <v-simple-table>
                        <template v-if="data.AnswerEventId != ''">
                          <thead>
                            <tr>
                              <th class="text-center">
                                <h6>Event Image</h6>
                              </th>
                              <th class="text-center">
                                <h6>Event Date</h6>
                              </th>
                              <th class="text-center">
                                <h6>Area</h6>
                              </th>
                              <th class="text-center">
                                <h6>Even Name</h6>
                              </th>
                              <th class="text-center">
                                <h6>Actions</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-center">
                            <tr>
                              <td>
                                <viewer rebuild :images="data.AnswerEventPhoto">
                                  <img
                                    style="padding: 5px"
                                    width="70"
                                    height="70"
                                    :src="src"
                                    :key="src"
                                    v-for="src in data.AnswerEventPhoto"
                                  />
                                </viewer>
                              </td>
                              <td>{{ data.AnswerEventDate }}</td>
                              <td>{{ data.AnswerPortfolioName }}</td>
                              <td>{{ data.AnswerEventName }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                    </v-col>
                  </v-row>
                </template>
                <br /><br />
              </v-col>
            </v-row>

            <template v-if="row != ''">
              <v-divider />
              <h3 class="text-center text-dark">Other Attachment Details</h3>
              <h6 class="text-dark mt-0">
                Add / Delete Attachment (max -
                {{ AllProjectMax }})
                <v-btn
                  icon
                  color="blue"
                  v-if="AllProjectMax > AllProjectCount"
                  @click="addAttachmentNewRow()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </h6>
              <br />
              <v-row>
                <v-col
                  v-for="(data, index) in row.AnswerAttachmentOfficers"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="index"
                >
                  <v-card>
                    <v-card-text>
                      <h6 class="text-right">
                        <v-btn icon color="red" @click="deleteRow1(index)">
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </h6>
                      <h6 align="center">
                        <!-- <img
                          style="padding: 5px"
                          width="70"
                          height="70"
                          :src="data.EventImagesArray"
                        /> -->
                        <viewer rebuild :images="data.EventImagesArray">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data.EventImagesArray"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{ data.EventDate }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{ data.EventName }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  v-for="(data1, k) in AllProject"
                  cols="12"
                  sm="12"
                  md="12"
                  lg="3"
                  xl="3"
                  :key="k"
                >
                  <v-card>
                    <v-card-text>
                      <h6 class="text-right">
                        <v-btn icon color="red" @click="deleteAttachmentRow(k)">
                          <v-icon>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                      </h6>
                      <h6 align="center">
                        <viewer rebuild :images="data1.AnswerEventImageUrl">
                          <img
                            style="padding: 5px"
                            width="70"
                            height="70"
                            :src="src"
                            :key="src"
                            v-for="src in data1.AnswerEventImageUrl"
                          />
                        </viewer>
                      </h6>
                      <h6>
                        Event Date -
                        <span class="text-dark">{{
                          data1.AnswerEventDate
                        }}</span>
                      </h6>
                      <h6>
                        Event Name -
                        <span class="text-dark">{{
                          data1.AnswerEventName
                        }}</span>
                      </h6>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-form>
          <div>
            <v-divider></v-divider>
            <h3 class="text-dark text-center mt-3">Video Details</h3>

            <v-row>
              <v-col cols="12" sm="12" lg="12" md="12">
                <h5 class="text-danger">* Notes for Video File:</h5>
                <h6 class="text-dark">
                  The video should be <br />
                  &nbsp; &nbsp; 1. In MP4 or AVI or MOV file format <br />
                  &nbsp; &nbsp; 2. Must be less than 5MB in file size
                </h6>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col
                cols="12"
                sm="12"
                lg="4"
                md="6"
                v-if="row.AwardVideoPath != null"
              >
                <h6 class="blue--text mb-4">Old Video</h6>
                <!-- <h6>{{ row.AwardVideoPath }}</h6> -->
                <video :src="row.AwardVideoPath" width="100%" controls></video>
              </v-col>
              <v-col cols="12" sm="12" lg="6" md="6" class="mt-3">
                <h4 class="text-dark">
                  Upload Video <small>(optional)</small>
                </h4>
                <v-file-input
                  ref="UploadVideo"
                  v-model="UploadVideo"
                  :rules="UploadVideoRules"
                  counter
                  show-size
                  truncate-length="47"
                  :clearable="false"
                  prepend-icon="mdi-video"
                  accept="video/*"
                  label="upload video here"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="resetUploadVideo">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </div>

          <v-row wrap v-if="ProgessStart == 1">
            <v-col align="center" cols="12" md="12">
              <v-progress-circular
                :rotate="180"
                :size="100"
                :width="15"
                :value="uploadPercentage"
                color="#00a4ef"
              >
                {{ uploadPercentage }}
              </v-progress-circular>
              <h3 class="red--text">
                Please wait, we are uploading your details. Time may vary based
                on your internet connection.
              </h3>
            </v-col>
          </v-row>

          <v-row class="mt-6">
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn
                  @click="resetForm"
                  elevation="30"
                  rounded
                  medium
                  color="brown"
                  class="font-size-h6 px-6 mr-3 white--text"
                >
                  Reset
                </v-btn> -->
              <v-btn
                v-if="row != ''"
                @click.prevent="getTotalNatConLomAwardsDetails(4)"
                :loading="SubmitFlag"
                elevation="30"
                rounded
                medium
                color="#1db954"
                class="font-size-h6 px-6 mr-3 white--text"
              >
                Partial Save
              </v-btn>
              <v-btn
                v-if="row != ''"
                :disabled="!valid1"
                @click.prevent="getTotalNatConLomAwardsDetails(3)"
                :loading="SubmitFlag"
                elevation="30"
                rounded
                medium
                color="blue"
                class="font-size-h6 px-6 white--text"
              >
                Final Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="70%">
      <answer-attachment-edit
        pageTitle="Add Project Details"
        :recordData="selectedRecord"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt($event)"
        v-if="addRecordPrompt"
      ></answer-attachment-edit>
    </v-dialog>

    <v-dialog v-model="addAttachmentRecordPrompt" persistent max-width="70%">
      <attachment-edit
        pageTitle="Add Project Details"
        :addRecordPrompt="addAttachmentRecordPrompt"
        @hideAddRecordPrompt="hideAddAttachmentRecordPrompt($event)"
        v-if="addAttachmentRecordPrompt"
      ></attachment-edit>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

import AnswerAttachmentEdit from "@/view/pages/erp/jci-award/natcon/common/AnswerAttachmentEdit.vue";
import AttachmentEdit from "@/view/pages/erp/jci-award/natcon/common/AttachmentEdit.vue";

export default {
  mixins: [common],
  components: {
    AnswerAttachmentEdit,
    AttachmentEdit,
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,
      OverlayLoadingFlag: false,

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      CardLoadingFlag: false,
      ResultFlag: false,
      UploadFlag: false,
      addButtonFlag: true,

      Status: "",

      NatconLomAwardStartDate: "",
      NatconLomAwardEndDate: "",
      TotalNatconLomAward: 0,
      TotalApplied: 0,
      TotalPartiallySaved: 0,

      StartDateFlag: false,
      EndDateFlag: false,

      rows: {},

      AwardId: "",
      AwardTypeId: "",

      UploadVideo: [],
      UploadVideoRules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Upload Video size should be less than 5 MB!",
      ],
      row: [],
      // CriteriaAnswers: [],
      Project: [],
      AllImages: [],
      AllProject: [],
      AllProjectMax: 10,
      AllProjectCount: 0,
      addAttachmentRecordPrompt: false,

      selectedRecord: "",
      Id: "",
      showTextBox: false,

      addRecordPrompt: false,

      ImageUploadFlag: false,

      AnswerRules: [(v) => !!v || "Answer is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "national_lom_award",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var id = this.$route.query.id;
      console.log({ id });
      this.AwardId = id;

      this.getTableRecords();
    },
    resetUploadVideo() {
      console.log("resetUploadVideo called.");
      this.UploadVideo = "";
    },
    resetForm() {
      console.log("resetForm called");
      var rows = this.CriteriaAnswers;

      rows.map((row, index) => {
        row.AnswerText = "";
        row.AllProject = {};
      });

      this.getTableRecords();
    },
    addNewRow(tr) {
      console.log("addNewRow called");
      console.log({ tr });
      this.selectedRecord = tr;
      this.addRecordPrompt = true;
    },
    deleteRow(id, index) {
      console.log("deleteRow called");
      console.log({ id });
      console.log({ index });
      if (index > -1) {
        var row = this.row.AwardAnswersArray;

        row.forEach((data, k) => {
          console.log({ k });
          if (id == k) {
            data.EventDetails.splice(index, 1);
          }
        });

        console.log("row = ", this.row);
      }
    },
    hideAddRecordPrompt(tr) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ tr });

      if (tr.EventId != "") {
        // this.Id = tr.Index;
        console.log("tr.index = ", tr.Index);

        var row = this.row.AwardAnswersArray;

        row.forEach((data, index) => {
          console.log("SNo = ", data.SNo);

          if (tr.Index == data.SNo) {
            console.log("Limit not Crossed");

            var n1 = data.QuestionsAttachmentMax;
            var n2 = data.EventDetails.length;
            console.log({ n1 }, { n2 });

            if (n1 > n2) {
              console.log("Limit not Crossed");

              data.EventDetails.push({
                EventId: tr.EventId,
                EventName: tr.EventName,
                EventDate: tr.EventDate,
                EventImagesArray: tr.EventPhoto,
              });
            } else {
              console.log("Maximum Limit Crossed");
              var Message = "Attachment Maximum Count reached its limit.";
              this.sweetAlert("error", Message, false);
            }
          }
        });

        console.log("row = ", this.row);
      }
    },
    deleteRow1(id) {
      console.log("deleteRow1 called");
      console.log({ id });

      this.row.AnswerAttachmentOfficers.splice(id, 1);

      this.calculateAllProjectCount();
    },

    calculateAllProjectCount() {
      console.log("calculateAllProjectCount called");
      this.AllProjectCount =
        this.row.AnswerAttachmentOfficers.length + this.AllProject.length;
      console.log("AllProjectCount =", this.AllProjectCount);
    },
    addAttachmentNewRow() {
      console.log("addAttachmentNewRow called");
      this.addAttachmentRecordPrompt = true;
    },
    deleteAttachmentRow(id) {
      console.log("deleteAttachmentRow called");
      console.log({ id });

      this.AllProject.splice(id, 1);
      this.AllImages.splice(id, 1);
    },
    hideAddAttachmentRecordPrompt(tr) {
      console.log("hideAddAttachmentRecordPrompt called");
      this.addAttachmentRecordPrompt = false;
      console.log({ tr });

      if (tr.EventName != "") {
        var count = this.AllProject.length;
        var id = count == 0 ? 0 : count + 1;

        this.AllProject.push({
          AnswerEventId: "",
          AnswerEventName: tr.EventName,
          AnswerEventDate: tr.EventDate,
          AnswerEventDescription: tr.EventDescription,
          AnswerEventImageName: tr.EventImageName,
          AnswerEventImageUrl: tr.EventImageUrl,
          AnswerEventImage: tr.EventImage,
        });

        this.AllImages.push({
          AnswerEventId: "",
          AnswerEventName: tr.EventName,
          UploadedImage: tr.EventImage.UploadedImage,
        });
      }
      console.log("AllProject = " + JSON.stringify(this.AllProject));
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var AwardId = this.AwardId;
      console.log({ AwardId });

      if (AwardId > 0) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award/show";
        var upload = {
          UserInterface: 1,
          Award: AwardId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records=" + JSON.stringify(records));
              thisIns.row = records;
              // thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Award details not found.";
        console.log("error=" + message);
      }
    },
    getTotalNatConLomAwardsDetails(status) {
      console.log("getTotalNatConLomAwardsDetails called");

      var validate1 = true;
      console.log({ validate1 });

      console.log({ status });
      this.Status = status;

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/total-awards";

        var AwardCategory = 4;
        var AwardGroup = 2;

        if (this.RoleId == 2) {
          // For Admin
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Lom: this.LomCode,
          };
        } else {
          // For Others
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.rows = records;
              thisIns.NatconLomAwardStartDate = records.StartDateTxt;
              thisIns.NatconLomAwardEndDate = records.EndDateTxt;
              thisIns.TotalNatconLomAward = records.TotalAward;
              thisIns.TotalApplied = records.TotalApplied;
              thisIns.TotalPartiallySaved = records.TotalPartiallySaved;
              thisIns.StartDateFlag = records.StartDateFlag;
              thisIns.EndDateFlag = records.EndDateFlag;

              console.log(
                "StartDateFlag=" +
                  records.StartDateFlag +
                  ", EndDateFlag=" +
                  records.EndDateFlag
              );

              if (thisIns.StartDateFlag && thisIns.EndDateFlag) {
                thisIns.confirmAlert(status);
              } else {
                thisIns.deadlineAlert();
              }
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var htmlTxt = "";
      htmlTxt +=
        "<h3> Natcon Lom Awards Bidding ended at <b> " +
        this.NatconLomAwardEndDate +
        " </b> </h3>";
      // htmlTxt += "<br/> Start <b> " + this.NatconLomAwardEndDate + " </b>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    confirmAlert(status) {
      console.log("confirmAlert called");

      var video_validate = false;
      var validate1 = false;

      video_validate =
        this.UploadVideo == "" ? true : this.$refs.UploadVideo.validate();
      validate1 = status == 4 ? true : this.$refs.form1.validate();
      console.log({ status }, { video_validate }, { validate1 });

      if (validate1 && video_validate) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", false);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "kindly answer all the questions.";
        }
        if (!video_validate) {
          message +=
            "The video should be <br/> 1. In MP4 or AVI or MOV file format <br/> 2. Must be less than 5MB in file size";
        }
        Swal.fire({
          // title: "Are you sure?",
          icon: "error",
          html: message,
        });
        // this.sweetAlert("error", message, false);
      }
    },
    submitForm(status) {
      console.log("submitForm is called");
      console.log({ status });

      this.ProgessStart = 1;
      this.SubmitFlag = true;
      this.OverlayLoadingFlag = true;

      var count = this.AllImages.length;

      var video = this.UploadVideo != {} ? this.UploadVideo : {};
      console.log({ video });

      console.log(
        "AnswerAttachmentOfficers = ",
        this.row.AnswerAttachmentOfficers
      );

      this.AllImages.forEach((row, index) => {
        console.log({ index });
        row.AnswerEventId = index;
        console.log("AllImages=" + JSON.stringify(this.AllImages));
      });

      this.AllProject.forEach((item, index) => {
        console.log({ index });
        item.AnswerEventId = index;
        console.log("AllProject=" + JSON.stringify(this.AllProject));
      });

      var inputData = {
        ActiveStatus: status,
        Award: this.AwardId,
        CriteriaAnswers: this.row,
        AnswerAttachmentOfficers: this.row.AnswerAttachmentOfficers,
        AllProject: this.AllProject,
        AllImagesCount: count,
      };
      console.log("inputData=" + JSON.stringify(inputData));

      let upload = new FormData();
      upload.append("UserInterface", 1);
      upload.append("InputData", JSON.stringify(inputData));
      upload.append("UploadVideo", video);

      this.AllImages.forEach((row, index) => {
        var id = index + 1;
        console.log({ id });
        var UploadedImage = row["UploadedImage"];
        var EventId = row["AnswerEventId"];
        console.log({ UploadedImage }, { EventId });

        upload.append("AllImages" + index, UploadedImage, EventId);
      });

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/award/national-award-update";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.ProgessStart = 0;
          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$router.push("/national/lom-award/list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.ProgessStart = 0;
          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>
